.scoreboard {
  text-align: center;
}

.winners {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
  height: 30vh; /* Altura ajustada do container */
}

.winner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  position: relative;
  height: 100%;
}

.trophyLottie {
  width: 100%;
  height: 200px;
  background-size: contain;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  z-index: 1; /* Garante que o avatar fique em cima do gráfico */
}

.avatar {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.name {
  margin-top: 5px;
}

.barContainer {
  width: 100px; /* Largura fixa para garantir igualdade */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  height: 100%;
}

.bar {
  width: 100%; /* Largura fixa para garantir igualdade */
  background-color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: 5px;
  position: relative; /* Mantém a barra no fluxo normal */
  bottom: 0;
}

.gold .bar {
  background-color: gold;
}

.silver .bar {
  background-color: silver;
}

.bronze .bar {
  background-color: #cd7f32; /* bronze color */
}

.score {
  font-weight: bold;
  color: white; /* Cor do texto da pontuação */
  position: absolute;
  top: 10px;
}

.participantList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.participantItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  color: white;
  background-color: #6a0dad; /* youpin purple */
  text-align: left;
}

.participantItem img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.participantName {
  margin-left: 10px;
  flex: 1; /* Adiciona flexibilidade para ocupar o espaço disponível */
}

.participantScore {
  font-weight: bold;
  color: white;
}
