.wordcloud-container {
  font-family: 'Montserrat Black';
  font-weight: 900;
  position: relative;
  width: 100%;
  height: 100%;
}

.wordcloud-container canvas {
  animation: fadeIn 0.6s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
