.quizPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.questionText {
  margin-top: 20px;
  color: #333;
  font-size: 2em; /* Tamanho de fonte ajustado */
  text-align: center;
}

.variableContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.imageContainer {
  padding-top: 25px;
  position: relative;
  width: 100%;
  max-height: 30vh; /* Ajuste conforme necessário */
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.questionImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.timerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  max-width: 200px; /* Tamanho máximo do timer */
  margin: auto;
  /* Centraliza o timer horizontalmente */
  font-family: "montserrat";
  font-weight: 900;
}

.timerOverlay {
  position: relative;
  margin-top: -25px;
  margin-left: -25px;
  width: 50px; /* Ajuste conforme necessário */
  height: 50px; /* Ajuste conforme necessário */
  background-color: white;
  border-radius: 100px;
  padding: 5px;
}

.optionsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Espaçamento entre as opções */
  margin-top: auto;
}

.optionItem {
  display: flex;
  padding: 15px 20px;
  border-radius: 5px;
  font-size: 1.5em; /* Tamanho de fonte ajustado */
  text-align: center;
  color: white; /* Texto branco */
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 0 1 calc(50% - 10px); /* Ajuste para 2 colunas por linha */
  box-sizing: border-box;
  min-width: 300px;
  min-height: 100px;
  align-items: center;
  justify-content: center;
}

.optionItem:hover {
  opacity: 0.8;
}

.optionColor1 {
  background-color: #E6C65A; /* Cor da primeira opção */
}

.optionColor2 {
  background-color: #3AAC60; /* Cor da segunda opção */
}

.optionColor3 {
  background-color: #D764FF; /* Cor da terceira opção */
}

.optionColor4 {
  background-color: #E65A5A; /* Cor da quarta opção */
}
