.modal {
    margin-left: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modalContent {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 10px;
    position: relative;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 5px;
  }
  
  .formGroup input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .optionItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
  }
  
  .colorPicker {
    width: 15px;
    height: 100%;
    border: none;
    margin-right: 10px;
    padding: 0;
    background: none;
    appearance: none;
    -webkit-appearance: none;
  }
  
  .colorPicker::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  
  .colorPicker::-webkit-color-swatch {
    border: none;
  }
  
  .colorPicker::-moz-color-swatch {
    border: none;
  }
  
  .optionContent {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    flex-grow: 1;
  }
  
  .optionText {
    flex-grow: 1;
    border: none;
    outline: none;
  }
  
  .deleteButton {
    display: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .deleteIcon {
    width: 20px;
    height: 20px;
    fill: white;
    background-color: #E65A5A;
    padding: 3px;
    border-radius: 10px;
  }
  
  .optionItem:hover .deleteButton {
    display: block;
  }
  
  .deleteButton:hover .deleteIcon {
    transform: scale(1.2);
  }
  
  .addOptionButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 10px;
  }
  
  .modalButton {
    background-color: #5d00c3;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .modalButton:hover {
    background-color: #3e0089;
  }
  
  @media (max-width: 768px) {
    .modal {
      margin-left: 0;
    }
  }
  