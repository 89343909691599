.waiting {
  text-align: center;
}

.participantsContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: 100%;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.participant {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.avatar {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin-bottom: 10px;
}
