/* src/pages/AccountHome.module.css */

/* AJUSTES PARA NAO CONFLITAR COM A SIDEBAR */
.container {
  margin-left: 340px;
  background-color: #FBF8FD;
  display: flex;
}

.mainContent {
  width: 100%;
}

.accountHomeMain {
  border-radius: 8px;
}

@media (max-width: 768px) {
  .mainContent {
    margin-top: 50px;
    margin-left: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }
}

/*  OUTROS AJUSTES */

.topSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 40px;
}

.welcome {
  display: flex;
  align-items: center;
}

.planBadge {
  font-size: 16px;
  font-weight: 400;
  color: white;
  background-color: #ccc;
  padding: 10px 30px;
  border-radius: 20px;
  margin-left: 10px;
}

.userActions {
  display: flex;
  align-items: center;
}

.upgradeButton {
  background-color: #d764ff;
  font-size: 16px;
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 10px;
}

.upgradeButton:hover {
  transform: scale(1.1) rotate(-5deg);

}

.userAvatar {
  background-color: #deacff;
  color: #5d00c3;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}


.userAvatar:hover{
  transform: scale(1.1);
}

.avatarImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}



/* INTERAÇÕES RÁPIDAS */

.interactionSection {
  background-color: white;
  padding: 1px 40px;
  border-radius: 0 0 0 60px;
}

.interactionSection h3 {
  color: #5d00c3;
}

.interactionCards {
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  gap: 20px;
  margin-top: 10px;
  padding-top: 15px;
}

.interactionCards {
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  gap: 20px;
  margin-top: 10px;
  padding-top: 15px;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.card {
  background-color: #FBF8FD;
  width: 15vw; /* Ajuste a largura para 25% da largura da viewport */
  height: 10vw; /* Ajuste a altura para 25% da largura da viewport */
  min-width: 200px; /* Define um valor máximo para a largura */
  min-height: 150px; /* Define um valor máximo para a altura */
  border-radius: 30px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.cardImage {
  width: 80%; /* Ajuste a largura da imagem dentro do card */
  height: auto; /* Mantém a proporção da imagem */
  object-fit: contain;
  cursor: pointer;
}

.cardImage:hover{
  transform: scale(1.2) rotate(5deg);
}

.cardTitle {
  font-weight: bold;
  margin-top: 10px;
  color: #5d00c3;
  font-size: 1em;
}

.comingSoon {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #5d00c3;
  color: white;
  padding: 10px 30px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
}

  /* Adicionando estilos para os botões de navegação */
.scrollButton {
  position: absolute;
  top: 55%;
  padding: 10px;
  background-color: #5d00c3;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}

.scrollButton svg {
  width: 80%;
  height: auto;
}

.scrollButton:hover {
  background-color: #450083;
  transform: scale(1.2);
}

.scrollLeft {
  left: 20px;
}

.scrollRight {
  right: 20px;
}

/* Ajuste para garantir que o contêiner tenha posição relativa */
.interactionSection {
  position: relative;
}





/* APRESENTAÇÕES RECENTES */
.recentPresentations {
  padding: 40px;
}

.presentationsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.presentationsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Ajuste para a descrição ficar abaixo do título */
.recentPresentationHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Alinhar itens à esquerda */
}

.presentationsHeader h3 {
  color: #5d00c3;
  margin-bottom: 5px;
  /* Adicione um espaçamento entre o título e a descrição */
}

.presentationsHeader p {
  color: #666;
  margin-top: 0;
  /* Remova qualquer margem superior */
}

.addPresentationButton {
  font-weight: bold;
  background-color: #5d00c3;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.addPresentationButton:hover{
  transform: scale(1.05) rotate(5deg);
}

.addPresentationButton img {
  margin-right: 10px;
}

.presentationCards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
}

.presentationCard {
  text-align: center;
  background-color: #fff;
  width: 300px;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  color: #666;
}

.presentationCard h4 {
  color: #5d00c3;
  margin-bottom: 10px;
}

.presentationActions {
  display: flex;
  justify-content: space-around;
  margin-top: auto;
}

.presentationActions button {
  width: 46px;
  height: 46px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.playButton {
  background-color: #5D00C3;
}

.duplicateButton {
  background-color: #E6C65A;
}

.editButton {
  background-color: #3AAC60;
}

.deleteButton {
  background-color: #E65A5A;
}

.playButton:hover, .duplicateButton:hover, .editButton:hover, .deleteButton:hover{
  transform: scale(1.05) rotate(-5deg);
}

.presentationUpdatedAt {
  color: #9F70D4;
  font-size: 12px;
  margin-top: 10px;
}


@media (max-width: 768px) {
  .recentPresentationHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Alinhar itens à esquerda */
  }

  .presentationsHeader {
    display: block;
  }

  .userAvatar{
    display: none;
  }

  .planBadge{
    display: none;
  }

  .upgradeButton{
    display: none;
  }

  .topSection{
    padding: 0px 40px 0px 40px;
  }

  .addPresentationButton {
   width: 100%;
   text-align: center;
   display: flex;
   justify-content: center;
   height: 60px;
  }

  .presentationCard {
    width: 100%;
  }

  .scrollButton {
   display: none;
  }

}



/* Configurações de SCROLL */

/* Para navegadores WebKit (Chrome, Safari, Edge) */
.interactionCards::-webkit-scrollbar {
  width: 0; /* Remove a barra de rolagem vertical */
  height: 0; /* Remove a barra de rolagem horizontal */
}

/* Opcional: se você quiser apenas estilizar a barra de rolagem e não removê-la completamente */

.interactionCards::-webkit-scrollbar {
  width: 8px; /* Ajuste a largura da barra de rolagem */
  height: 8px; /* Ajuste a altura da barra de rolagem */
}

.interactionCards::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Cor da barra de rolagem */
  border-radius: 10px; /* Borda arredondada */
}

.interactionCards::-webkit-scrollbar-track {
  background: transparent; /* Fundo da trilha */
}


/* Para navegadores Firefox */
.interactionCards {
  scrollbar-width: none; /* Remove a barra de rolagem */
  -ms-overflow-style: none; /* Remove a barra de rolagem no IE 10+ */
}

/* Opcional: estilizar a barra de rolagem no Firefox */
/*
.interactionCards {
  scrollbar-width: thin; /* Barra de rolagem fina 
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent; /* Cor da barra de rolagem e da trilha
}
*/

