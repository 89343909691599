.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  margin-left: 300px;
  width: calc(100% - 300px);
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal.show {
  display: block;
}

.modalContent {
  background-color: #fefefe;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modalBody {
  margin-top: 10px;
}

.modalBody label {
  display: block;
  margin-bottom: 5px;
}

.modalBody input[type="text"],
.modalBody input[type="number"] {
  width: calc(100% - 30px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: inline-block;
}

.imageInputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imageLinkContainer,
.imageUploadContainer {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.optionItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.optionCheckbox {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.optionText {
  width: calc(100% - 60px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: inline-block;
}

.optionText.error,
.modalBody input[type="text"].error,
.modalBody input[type="number"].error {
  border-color: red;
}

.deleteButton {
  background-color: #e65a5a;
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 20px;
  height: 20px;
  fill: white;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.cancelButton,
.saveButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancelButton {
  background-color: #e65a5a;
  color: white;
  margin-right: 10px;
}

.saveButton {
  background-color: #007bff;
  color: white;
}

.errorMessage {
  color: red;
  margin-right: auto;
  align-self: center;
}

.previewImage {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 5px;
}


@media (max-width: 768px) {

  .modal {
  margin-left: 0;
  width: 100%;
  }


}