/* src/pages/Participate.module.css */
.participateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FBF8FD;
  height: 100vh;
}

.participateHeader {
  display: grid;
  grid-template-columns: 1fr 6fr 1fr; /* Definindo a divisão 2/4/2 */
  align-items: center;
  background-color: #FBF8FD;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
}

.headerButton {
  background: none;
  border: none;
  color: #5D00C3;
  cursor: pointer;
  font-size: 16px;
  justify-self: center; /* Centralizar botões */
}

.headerLogo {
  justify-self: center; /* Centralizar logo */
  height: 50px;
}

.participateContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.participateContent h2 {
  text-align: center;
  width: 100%;
}

.pinArea {
  margin: 0;
  font-size: 1em; /* Ajusta o tamanho do texto do PIN */
}

.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputField {
  width: 70%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #CCCCCC;
  border-radius: 50px;
  text-align: center;
  margin-right: 10px; /* Espaço entre o input e o botão */
}

.submitButton {
  width: auto;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #6A15C8;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}