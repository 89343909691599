.pollForm {
  display: flex;
  flex-direction: column;
}

.optionItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.colorPicker {
  width: 15px;
  height: 100%;
  border: none;
  margin-right: 10px;
  padding: 0;
  background: none;
  appearance: none;
  -webkit-appearance: none;
}

.colorPicker::-webkit-color-swatch-wrapper {
  padding: 0;
}

.colorPicker::-webkit-color-swatch {
  border: none;
}

.colorPicker::-moz-color-swatch {
  border: none;
}

.optionContent {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px;

  flex-grow: 1;
}

.correctCheckbox {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.optionText {
  flex-grow: 1;
  border: none;
  outline: none;
}

.deleteButton {
  display: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.deleteIcon {
  width: 20px;
  height: 20px;
  fill: white;
  background-color: #E65A5A;
  padding: 3px;
  border-radius: 10px;
}

.optionItem:hover .deleteButton {
  display: block;
}

.deleteButton:hover .deleteIcon {
  transform: scale(1.2);
}

.addOptionButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 10px;
}
