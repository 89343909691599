/* FOOTER */
.footer {
    background-color: #F5F5F5;
    padding: 40px 0px;
    width: 100%;
  }
  
  .footerContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  
  .footerLogo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1;
  }
  
  .footerLogo img {
    height: 50px;
  }
  
  .footerLogo p {
    margin: 10px 0 0;
    color: #6A15C8;
    font-weight: bold;
  }
  
  .footerSocials {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .footerSocials a {
    display: inline-block;
    width: 24px;
    height: 24px;
  }
  
  .footerSocials img {
    width: 100%;
    height: 100%;
  }
  
  .footerLinks {
    display: flex;
    flex: 2;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .footerColumn {
    flex: 1;
    min-width: 150px;
    margin-bottom: 20px;
  }
  
  .footerColumn h4 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #333;
    border-bottom: 2px solid #333;
    padding-bottom: 5px;
  }
  
  .footerColumn ul {
    list-style: none;
    padding: 0;
  }
  
  .footerColumn ul li {
    margin-bottom: 10px;
  }
  
  .footerColumn ul li a {
    color: #333;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footerColumn ul li a:hover {
    color: #6A15C8;
  }
  
  /* Assinatura */
  .signature {
    text-align: center;
    padding: 20px 0;
    background-color: #FBF8FD;
    font-size: 0.9em;
    color: #333;
  }
  
  .signature p {
    margin: 0;
  }
  
  .signature a {
    color: #6A15C8;
    text-decoration: none;
    font-weight: bold;
  }
  
  .signature a:hover {
    text-decoration: underline;
  }
  
  /* RESPONSIVE */
  @media (max-width: 768px) {
    .footerContainer {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .footerLogo {
      margin-bottom: 20px;
    }
  
    .footerLinks {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  
    .footerColumn {
      width: 100%;
      max-width: 300px;
      margin-bottom: 20px;
    }
  
    .footerSocials {
      justify-content: center;
    }
  }
  