/* src/interactions/MultimediaParticipant.module.css */
.multimediaParticipantContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fbf8fd;
}

.mediaContent {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin: 20px 0;
}

.mediaText {
  font-size: 1.2rem;
  margin: 20px 0;
  text-align: center;
}

.mediaButton {
  padding: 10px 20px;
  background-color: #5d00c3;
  color: white;
  text-decoration: none;
  border-radius: 10px;
  margin: 20px 0;
  text-align: center;
}

.reactionButtons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.reactionButton {
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 10px;
}
