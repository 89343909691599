.pollParticipantContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  background-color: #fbf8fd;
}

.questionContainer {
  margin-bottom: 20px;
  text-align: center;
}

.questionContainer h2 {
  color: #5d00c3;
  font-size: 1.5em;
  font-weight: bold;
}

.chartContainer {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.chart {
  display: flex;
  justify-content: space-around;
  width: 80%;
  height: 20vh;
  position: relative;
  padding-bottom: 20px; /* Espaço na parte inferior */
}

.barContainer {
  width: 16%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 5px; /* Adiciona bordas arredondadas */
}

.countLabel {
  margin-top: 5px;
  font-size: 0.8rem;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.optionButton {
  width: 80%;
  padding: 15px;
  margin: 10px 0;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.optionButton:hover {
  opacity: 0.8;
}

.optionButton:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.selected {
  border: 2px solid #000;
}

.selectionHint {
  text-align: center;
  margin-top: 10px;
  color: #888;
}
