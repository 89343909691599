.scoreboard {
    padding: 20px;
    text-align: center;
  }
  
  .participantList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .participantItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    color: white;
  }
  
  .participantItem img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  
  .gold {
    background-color: gold;
  }
  
  .silver {
    background-color: silver;
  }
  
  .bronze {
    background-color: #cd7f32; /* bronze color */
  }
  
  .purple {
    background-color: #6a0dad; /* youpin purple */
  }
  
  .name {
    flex: 1;
    text-align: left;
    margin-left: 10px;
  }
  
  .score {
    font-weight: bold;
  }
  