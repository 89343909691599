/* HEADER DESKTOP */
.homeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: #6A15C8;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  
}

.logo img {
  height: 50px;
}

.homeNav {
  display: flex;
  align-items: center;
}

.homeNav a {
  margin: 0 10px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.homeNav a:hover{
  transform: scale(1.1);
}

.actionButtons {
  display: flex;
  gap: 10px;
}

.actionButtons a {
  margin: 0 10px;
  padding: 10px 30px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
}

.highlightButton {
  background-color: #DA71FF;
  color: white;
  transition: background-color 0.3s ease;
}

.highlightButton:hover {
  background-color: #8d27b3;
}

.signInButton {
  background-color: #5D00C3;
  color: white;
  transition: background-color 0.3s ease;
}

.signInButton:hover {
  background-color: #490097;
  color: white;
}

.menuIcon {
  display: none;
  cursor: pointer;
}

.accountIcon{
  display: none; /* Mostrar o ícone do menu no mobile */
  cursor: pointer;
}

.mobileMenu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  padding-top: 20px;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.mobileMenu.open {
  transform: translateX(0);
}

.mobileMenuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px; /* Adiciona padding para espaçamento */
}

.closeIcon {
  position: absolute;
  right: 10px;
  top: 10px; /* Adiciona 'px' para consistência */
  cursor: pointer;
}

.mobileNav {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.mobileNav a {
  padding: 15px 0;
  color: #6A15C8;
  text-decoration: none;
  width: 80%;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.mobileNav a:hover {
  background-color: #f0e5ff;
  color: #8d27b3;
}

.mobileActionButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 0;
  width: 80%;
  align-items: center;
  position: absolute;
  bottom: 50px;
}

.mobileActionButtons a {
  margin: 0;
  padding: 20px 30px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  width: 80%; /* Ajusta a largura para evitar corte */
  text-align: center;
}

.mobileActionButtons .highlightButton {
  background-color: #DA71FF;
  color: white;
  transition: background-color 0.3s ease;
}

.mobileActionButtons .highlightButton:hover {
  background-color: #8d27b3;
}

.mobileActionButtons .signInButton {
  background-color: #5D00C3;
  color: white;
  transition: background-color 0.3s ease;
}

.mobileActionButtons .signInButton:hover {
  background-color: #490097;
  color: white;
}

/* HEADER MOBILE */
@media (max-width: 768px) {
  .homeHeader {
    padding: 10px;
    justify-content: center; /* Centraliza o conteúdo */
    position: relative; /* Necessário para posicionamento absoluto */
  }

  .homeNav {
    display: none; /* Ocultar a navegação no desktop */
  }

  .menuIcon {
    display: block; /* Mostrar o ícone do menu no mobile */
    position: absolute;
    left: 20px; /* Espaçamento da borda esquerda */
   
  }

  .menuIcon img{
      max-width: 40px; /* Define a largura máxima do ícone */
      max-height: 40px; /* Define a altura máxima do ícone */
  }

  .accountIcon{
    display: block; /* Mostrar o ícone do menu no mobile */
    position: absolute;
    right: 40px; /* Espaçamento da borda esquerda */
  }

  .accountIcon img{
    width: auto;; /* Define a largura máxima do ícone */
    max-height: 35px; /* Define a altura máxima do ícone */
    
  }

  .logo {
    position: relative;
  }

  .closeIcon {
    position: absolute;
    right: 30px;
    top: 30px;
  }

  .closeIcon img{
    width: auto; /* Define a largura máxima do ícone */
    max-height: 30px; /* Define a altura máxima do ícone */
  }

  .mobileMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo img {
    height: 50px;
  }
}
