/* src/interactions/InsightsProjection.module.css */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.insightsProjectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
}

.insightsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  overflow-y: auto;
}

.insightItem {
  background-color: #5d00c3;
  color: white;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
  text-align: center;
  align-content: center;
  flex-grow: 1;
  opacity: 0; /* Inicialmente invisível */
  animation: fadeIn 0.5s forwards; /* Animação de entrada */
  transition: flex-basis 0.5s ease-in-out, transform 0.5s ease-in-out;
  min-height: 60px;
  font-size: 1.2em;
  font-weight: 600;
}

.widthSmall {
  flex: 1 1 150px;
}

.widthMedium {
  flex: 2 1 300px;
}

.widthLarge {
  flex: 3 1 450px;
}
