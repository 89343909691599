/* src/interactions/WordCloudParticipant.module.css */
.wordcloudParticipantContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  top: auto;
  font-weight: 900;
}

.wordcloudInputContainer {
  position: fixed;
  bottom: 30px;
  width: calc(100% - 40px); /* Ajustar largura para caber na tela com margens */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.wordcloudInput {
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  height: 40px;
  border: none;
  border-radius: 50px 0 0 50px; /* Borda arredondada apenas à esquerda */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: box-shadow 0.3s ease-in-out;
}

.wordcloudInput::placeholder {
  color: #D8C2F1;
}

.wordcloudSubmit {
  height: 60px;
  padding: 10px 30px;
  background-color: #5D00C3;
  color: white;
  border: none;
  border-radius: 0 50px 50px 0; /* Borda arredondada apenas à direita */
  cursor: pointer;
}
