/* src/interactions/multimedia/MultimediaPreview.module.css */

.previewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FBF8FD;
  height: auto;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
}

.previewContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.multimediaImage {
  max-width: 100%;
  max-height: calc(65vh - 200px); /* Ajustar para garantir que a imagem não ocupe mais de 70vh menos espaço reservado para outros elementos */
  height: auto;
  border-radius: 10px;
  margin: 20px 0;
  object-fit: contain; /* Garante que a imagem seja redimensionada sem cortar */
}

.multimediaText {
  font-size: 1.2em;
  text-align: center;
  margin: 10px;
  max-width: 90%; /* Garante que o texto não ultrapasse a largura disponível */
}

.multimediaButton {
  background-color: #5D00C3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  font-size: 1em;
  margin: 10px 0;
  display: inline-block;
}

.emojiContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.emojiBubble {
  position: absolute;
  font-size: 2em;
  animation: bubble 2s ease-in-out forwards;
}

@keyframes bubble {
  0% {
    opacity: 1;
    transform: scale(2);
  }
  100% {
    opacity: 0;
    transform: scale(4);
  }
}
