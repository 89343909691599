/* src/pages/Learn.module.css */

.learnContainer {
  display: flex;
  
  margin-left: 340px;
  background-color: #FBF8FD;
}

.mainContent {
  /* Espaço para o sidebar */
  width: 100%;
}

.learnMain {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .mainContent {
    margin-top: 50px;
    margin-left: 0;
  }

  .learnContainer {
    margin: 0;
    display: flex;
    flex-direction: column;
  }
}