/* src/pages/Projection.module.css */

* {
  transition: all 0.2s;
}

.projectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FBF8FD;
  height: 100vh;
}

.pinArea {
  font-size: 12px;
}

.projectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #5D00C3;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 20px;
  width: calc(100% - 80px); /* Ajustar largura para caber na tela com margens */
}

.projectionLogo {
  height: 50px;
}

.headerGroup {
  display: flex;
  align-items: center;
}

.headerText {
  margin-left: 10px;
  font-size: 1.2em;
}

.headerPin, .headerParticipants {
  background-color: white;
  color: #5D00C3;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 10px;
}

.projectionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centralizar verticalmente */
  flex-grow: 1;
  width: 100%;
  margin-top: 20px;
  overflow: hidden; /* Evitar scroll */
}

.projectionContent h2 {
  margin: 20px 0;
}

.wordcloudContainer {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.controlPanel {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.controlButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 5px 0;
  border: none;
  border-radius: 15px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.controlButton:hover {
 transform: scale(1.2);
}

.controlButton img {
  height: 50%;
  width: auto;
}

.controlPurple {
  background-color: #5D00C3;
}

.controlPink {
  background-color: #E91E63;
}

.controlGreen {
  background-color: #4CAF50;
}
