.pollPreview {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
}

.pollPreview h2 {
    margin-bottom: 20px;
    color: #5d00c3;
    font-size: 2em;
    font-weight: bold;
    text-align: center;
}

.chartContainer {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    position: relative;
}

.chart {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 400px;
    position: relative;
    padding-bottom: 20px; /* Espaço na parte inferior */
}

.barContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    max-width: 135px;
    margin: 0 10px;
    position: relative;
    justify-content: flex-end; /* Alinhamento na parte inferior */
}

.bar {
    width: 100%;
    border-radius: 5px 5px 0 0;
    transition: height 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: inherit; /* Adiciona cor de fundo herdada */
}

.countLabel {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    font-weight: bold;
    color: #000;
}

.optionLabels {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin-top: 10px;
}

.optionLabel {
    flex: 1;
    max-width: 135px;
    text-align: center;
    font-weight: bold;
    padding: 10px 0;
    border-radius: 5px;
    color: white;
    align-content: center;
}
