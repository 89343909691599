.container {
  margin-left: 340px;
  background-color: #FBF8FD;
  display: flex;
  min-height: 100vh;
}

.mainContent {
  width: 100%;
}

.accountHomeMain {
  display: flex;
  border-radius: 8px;
}

.topSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
}

.welcome {
  display: flex;
  align-items: center;
  color: #5D00C3;
}

.planBadge {
  font-size: 16px;
  font-weight: 400;
  color: white;
  background-color: #ccc;
  padding: 10px 30px;
  border-radius: 20px;
  margin-left: 10px;
}

.userActions {
  display: flex;
  align-items: center;
}

.upgradeButton {
  background-color: #d764ff;
  font-size: 16px;
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 10px;
}

.upgradeButton:hover {
  transform: scale(1.1) rotate(-5deg);
}

.userAvatar {
  background-color: #deacff;
  color: #5d00c3;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.userAvatar:hover {
  transform: scale(1.1);
}

.avatarImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profileOptions {
  width: 250px;
  padding: 40px;
}

.profileOptions ul {
  list-style-type: none;
  padding: 0;
}

.profileOptions li {
  margin-bottom: 15px;
  cursor: pointer;
  color: #333;
}

.profileOptions .active {
  font-weight: bold;
  color: #5d00c3;
}

.profileContent {
  flex: 1;
  padding: 40px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 40px;
}

.profileContent h3 {
  color: #5D00C3;
}

.profileBox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profilePhoto {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 40px;
}

.avatar {
  width: 100px;
  height: 100px;
  background-color: #deacff;
  color: #5d00c3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
}

.uploadButton {
  display: none; /* Ocultar o input file */
}

.customUploadButton {
  background-color: #5d00c3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
}

.customUploadButton:hover {
  background-color: #490592;
  transform: rotate(3deg) scale(1.1);
}
.profileDetails {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formGroup label {
  margin-bottom: 5px;
  color: #333;
}

.formGroup input {
  padding: 15px;
  border: none;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.formGroup input:hover {
  transform: translateY(-5px);
  border: solid #DA71FF 1px;
}

.profileActions {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.cancelButton {
  background-color: #ccc;
  color: #333;
  padding: 15px 25px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 10px;
}

.saveButton {
  background-color: #5D00C3;
  color: white;
  padding: 15px 25px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 600;
}

.cancelButton:hover {
  background-color: #999;
}

.saveButton:hover {
  background-color: #490592;
  transform: rotate(3deg) scale(1.1);
}

.error {
  color: red;
  margin-bottom: 10px;
}

.success {
  color: green;
  margin-bottom: 10px;
}

.loading {
  text-align: center;
  padding: 20px;
}

.backButton {
  display: none;
}




@media (max-width: 768px) {
  .container {
    margin-left: 0;
  }

  .userActions{
    display: none;
  }

  .welcome{
    margin-top: 100px;
    text-align: center;
    width: 100%;
    justify-content: center;
  }

  .profileOptions {
    width: 100%;
    padding: 20px;
  }

  .profileOptions.show {
    display: block;
  }

  .profileOptions.hide {
    display: none;
  }

  .profileOptions ul {
    display: flex;
    flex-direction: column;
  }

  .profileOptions li {
    background-color: white;
    padding: 30px 10px;
    border-radius: 8px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #5d00c3;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .profileContent {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
  }

  .profileBox{
    flex-direction: column;
  }

  .profilePhoto{
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .profileDetails{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .profileContent h3{
    text-align: center;
  }

  .profileActions{
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    margin-top: 10px;
  }

  .profileContent.hide {
    display: none;
  }

  .backButton {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #5d00c3;
    height: 50px;
    width: 50px;
    min-width: 50px;
    border-radius: 15px;
    border: none;
  }
  
  .backButton:hover {
    background-color: #410086;
    text-shadow: 0 0 5px #6e6e6e;
    transform: translateY(-3px); /* Leve levantada */
  }
  
}


