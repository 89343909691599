@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.insightsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start; /* Alinha os itens ao topo */
  align-content: flex-start; /* Garante que as linhas se alinhem ao topo */
  width: 100%;
  padding: 10px;
  overflow-y: auto; /* Adiciona scroll vertical quando necessário */
  max-height: 100%; /* Garante que a altura máxima não ultrapasse o contêiner */
}

.insightItem {
  background-color: #5d00c3;
  color: white;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
  text-align: center;
  align-content: center;
  flex-grow: 1;
  opacity: 0; /* Inicialmente invisível */
  animation: fadeIn 0.5s forwards; /* Animação de entrada */
  transition: flex-basis 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.widthSmall {
  flex: 1 1 150px;
}

.widthMedium {
  flex: 2 1 300px;
}

.widthLarge {
  flex: 3 1 450px;
}
