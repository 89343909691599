/* src/interactions/MultimediaProjection.module.css */

.multimediaProjectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centralizar verticalmente */
  width: 100%;
  height: 100%;
  overflow: hidden; /* Evitar scroll */
  position: relative; /* Adicionado para posicionamento absoluto dos emojis */
}

.multimediaImage {
  max-width: 100%;
  max-height: calc(100vh - 400px); /* Ajuste para evitar que a imagem empurre outros elementos */
  height: auto;
  border-radius: 10px;
  margin: 20px 0;
}

.multimediaText {
  font-size: 1.2em;
  text-align: center;
  margin: 20px;
}

.multimediaButton {
  background-color: #5D00C3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  font-size: 1em;
  margin: 20px 0;
  display: inline-block; /* Garantir que o botão seja exibido como bloco */
}

.emojiContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Garantir que os emojis não interfiram com outros elementos */
}

.emojiBubble {
  position: absolute;
  font-size: 2em;
  animation: bubble 2s ease-in-out forwards;
}

@keyframes bubble {
  0% {
    opacity: 1;
    transform: scale(2);
  }
  100% {
    opacity: 0;
    transform: scale(4);
  }
}
