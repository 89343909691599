/* src/pages/Plans.module.css */

.plansContainer {
    display: flex;
    margin-left: 340px;
    background-color: #FBF8FD;
  }
  
  .mainContent {
    width: 100%;
  }
  
  .plansMain {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .mainContent {
      margin-top: 50px;
      margin-left: 0;
    }
  
    .plansContainer {
      display: flex;
      flex-direction: column;
      margin: 0;
    }
  }