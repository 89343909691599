.joinContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background-color: #FBF8FD;
  padding-top: 50px;
  box-sizing: border-box;
}

.joinLogo {
  width: 200px;
  margin-bottom: 20px;
}

.joinWordcloud {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden; /* Evita overflow */
}

.joinForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: 100px; /* Adiciona margem inferior */
}

.joinInput {
  width: 300px;
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: 2px solid #CCCCCC;
  border-radius: 50px;
  text-align: center;
}

.joinButton {
  width: 320px;
  padding: 10px;
  font-size: 18px;
  color: white;
  background-color: #6A15C8;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin-bottom: 10px;
}

.joinLink {
  font-size: 16px;
  color: #6A15C8;
  text-decoration: none;
}

.joinLink:hover {
  text-decoration: underline;
}
