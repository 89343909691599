.sidebar {
  padding: 0 20px;
  background-color: #f8f9fa;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  transition: all 0.3s ease-in-out;
  width: 300px;
  box-shadow: 10px 0 30px rgba(0, 0, 0, 0.1);
  z-index: 10; /* Garantir que o sidebar esteja sobre o conteúdo */
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

.nav {
  width: 100%;
  flex-grow: 1;
}

.nav ul {
  list-style: none;
  padding: 0;
}

.nav li {
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.nav a {
  text-decoration: none;
  color: #666666;
  display: flex;
  align-items: center;
  transition: color 0.3s, fill 0.3s;
}

.nav a img,
.nav a svg {
  margin-right: 10px;
  padding: 12px;
  background-color: #e6e6e6; /* Cor de fundo da caixa */
  border-radius: 13px; /* Bordas arredondadas */
  transition: background-color 0.3s, fill 0.3s;
}

.nav a:hover {
  color: #430b81;
}

.active a {
  font-weight: bold;
  color: #5d00c3;
}

.active a img,
.active a svg {
  background-color: #5d00c3; /* Cor de fundo da caixa */
  color: white;
}

.comingSoon {
  font-weight: 600;
  background-color: #5d00c3;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  margin-left: 10px;
  font-size: 12px;
}

.logoutButton {
  position: absolute;
  bottom: 30px;
  padding: 10px 30px;
  background-color: #5d00c3;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.logoutButton:hover {
  background-color: #430b81;
}

.mobileHeader {
  display: none; /* Escondido por padrão */
  width: 100%;
  background-color: #f8f9fa;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20; /* Garantir que o header esteja sobre o conteúdo */
}

.mobileLogo {
  width: 50px;
}

.userPlan {
  color: white;
  background-color: #ccc;
  padding: 10px 30px;
  border-radius: 20px;
}

.menuButton {
  display: none; /* Escondido por padrão */
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 30px;
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
    position: fixed;
    width: 100vw;
    box-shadow: none;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .mobileHeader {
    display: flex; /* Mostrar no mobile */
  }

  .menuButton {
    display: block; /* Mostrar no mobile */
  }
}
