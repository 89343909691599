/* src/interactions/wordcloud/WordcloudPreview.module.css */

.wordcloudContainer {
  width: 100%;
  height: 100%; /* Garante que ocupe toda a altura disponível */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Evitar scroll */
  font-weight: 900;
}
