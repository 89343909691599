.quizPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.questionText {
  font-size: 1.8em;
  margin: 20px 0;
}

.variableContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.imageContainer {
  padding-top: 25px;
  position: relative;
  width: 100%;
  max-height: 30vh; /* Ajuste conforme necessário */
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.questionImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.timerContainer {
  /* z-index: 2; */
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timerOverlay {
  position: relative;
  margin-top: -25px;
  margin-left: -25px;
  width: 50px; /* Ajuste conforme necessário */
  height: 50px; /* Ajuste conforme necessário */
  background-color: white;
  border-radius: 100px;
  padding: 5px;
}

.optionsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 20px 0;
}

.optionItem {
  display: flex;
  padding: 15px 20px;
  border-radius: 5px;
  font-size: 1.2em;
  text-align: center;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 0 1 calc(50% - 10px);
  box-sizing: border-box;
  min-width: 300px;
  min-height: 100px;
  align-items: center;
  justify-content: center;
}

.optionItem:hover {
  opacity: 0.8;
}

.optionColor1 {
  background-color: #E6C65A;
}

.optionColor2 {
  background-color: #3AAC60;
}

.optionColor3 {
  background-color: #D764FF;
}

.optionColor4 {
  background-color: #E65A5A;
}

.addQuestionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.addQuestionText {
  color: black;
  font-size: 1.2em;
  cursor: default;
}
