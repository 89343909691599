/* Question.module.css */
.question {
  text-align: center;
}

.questionText {
  font-size: 24px;
  margin-bottom: 20px;
}

.timerContainer {
  width: 100px;
  height: 100px;
  margin: 0 auto 20px auto;
}

.questionImage {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.optionButton {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  font-size: 18px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.optionColor1 { background-color: #ffab00; }
.optionColor2 { background-color: #ff5722; }
.optionColor3 { background-color: #4caf50; }
.optionColor4 { background-color: #2196f3; }

.optionButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
