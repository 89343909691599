/* src/pages/AiAssistant.module.css */

.aiAssistantContainer {
  margin-left: 340px;
  background-color: #FBF8FD;
  display: flex;
  margin-left: 340px;
  background-color: #FBF8FD;
  display: flex;
}

.mainContent {
  width: 100%;
}

.aiAssistantMain {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .mainContent {
    margin-top: 50px;
    margin-left: 0;
  }

  .aiAssistantContainer {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
}