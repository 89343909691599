.answerRevealed {
    text-align: center;
    padding: 20px;
  }
  
  .questionTitle {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .avatarContainer {
    margin-bottom: 20px;
  }
  
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  
  .correctMessage {
    color: green;
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .incorrectMessage {
    color: red;
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .scoreContainer {
    font-size: 36px;
    color: green;
    margin-bottom: 20px;
  }
  
  .optionsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .optionItem {
    width: 60%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    background-color: #e0e0e0;
  }
  
  .correct {
    background-color: #a5d6a7;
  }
  
  .chosenOption {
    font-size: 18px;
    margin-top: 20px;
  }
  
  .totalScoreContainer {
    font-size: 18px;
    margin-top: 20px;
  }
  
  .totalScore {
    font-weight: bold;
  }
  