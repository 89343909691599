/* src/interactions/quiz/QuizQuickCreateModal.module.css */
.modal {
    margin-left: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modalContent {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    border-radius: 10px;
    position: relative;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 5px;
  }
  
  .formGroup input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .questionItem {
    display: flex;
    background-color: white;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: background-color 0.3s;
    cursor: pointer;
  }
  
  .questionItem:hover {
    background-color: #f1f1f1;
  }
  
  .selectedItem {
    border: 2px solid #5d00c3;
  }
  
  .contentColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .questionText {
    font-weight: bold;
    color: #5d00c3;
    margin: 0;
  }
  
  .interactionButtons {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  
  .icon {
    width: 15px;
    height: 15px;
    fill: white;
    padding: 8px;
    border-radius: 10px;
  }
  
  .icon:hover {
    transform: scale(1.2);
  }
  
  .editButton,
  .duplicateButton,
  .deleteButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .editButton .icon {
    background-color: #3AAC60;
  }
  
  .duplicateButton .icon {
    background-color: #E6C65A;
  }
  
  .deleteButton .icon {
    background-color: #E65A5A;
  }
  
  .addOptionButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 10px;
  }
  
  .modalButton {
    background-color: #5d00c3;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .modalButton:hover {
    background-color: #3e0089;
  }
  