.participantContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.avatarSelector {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.avatars {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: 200px;
}

.avatar {
  width: 160px;
  height: 160px;
  margin: 0 5px;
  transition: transform 0.3s, opacity 0.3s;
  cursor: pointer;
}

.selected {
  transform: scale(1.2);
  opacity: 1;
}

.unselected {
  transform: scale(0.8);
  opacity: 0.3;
}

.inputContainer {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}

.nameInput {
  width: calc(100% - 40px);
  max-width: 400px;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submitButton {
  background-color: #6200ea;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 20px;
}

.submitButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectedAvatar {
  width: 160px;
  height: 160px;
}

.participantName {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}
