.betaAlertContainer {
    position: relative;
    transition: top 0.5s ease-in-out;
    top: 0;
}

.betaAlert {
    background-color: #F84848;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute; /* Alerta posicionado absolutamente */
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    overflow: hidden;
    transition: top 0.5s ease-in-out;
}

.betaAlert.show {
    top: 0;
}

.betaAlert.hide {
    top: -100%; /* Mover o alerta para fora da tela */
}

.betaBadge {
    background-color: #5D00C3;
    color: white;
    border-radius: 50px;
    padding: 10px 20px;
    margin: 0;
    width: 100%;
    text-align: center;
}

.betaAlertContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px 20px;
    width: 100%;
}

.link {
    color: white;
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap; /* Evita quebra de linha */
    font-weight: 600;
}

.text {
    white-space: nowrap; /* Evita quebra de linha */
}

.betaAlertClose {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    color: white;
    text-decoration: underline;
    font-size: 16px;
}

@media (min-width: 600px) {
    .betaAlert {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        padding: 0 20px;
    }

    .betaBadge {
        margin: 10px 0;
        width: auto;
        text-align: left;
    }

    .betaAlertContent {
        flex-direction: row;
        text-align: left;
        padding: 0 10px;
    }

    .betaAlertClose {
        position: absolute;
        right: 20px;
        font-size: 16px;
    }
}
