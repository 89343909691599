/* src/components/PreviewContainer.module.css */

.previewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FBF8FD;
  height: 80vh;
  width: 100%;
  overflow: hidden;
  padding: 20px; /* Adiciona padding para evitar cortes nas bordas */
  box-sizing: border-box; /* Inclui padding na largura total */
  border-radius: 20px;
}

.previewContent {
  display: flex;
  flex-direction: column;
  align-items: center; /* Alinha ao centro horizontalmente */
  flex-grow: 1;
  width: 100%;
  height: 100%; /* Garante que ocupe toda a altura disponível */
  overflow: hidden; /* Evitar scroll */
}
