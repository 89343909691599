/* src/components/PreviewHeader.module.css */

.projectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #5D00C3;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box; /* Inclui padding na largura total */
  }
  
  .projectionLogo {
    height: 50px;
  }
  
  .headerGroup {
    display: flex;
    align-items: center;
  }
  
  .headerText {
    margin-left: 10px;
    font-size: 1.2em;
  }
  
  .headerPin, .headerParticipants {
    background-color: white;
    color: #5D00C3;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 10px;
  }
  