body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}


/* Inicio */
.homeBG {
  background-color: #FBF8FD;
  margin: 0;
}

.homeContainer {
  flex-direction: column;
  font-family: Arial, sans-serif;
}

/* PIN AREA */

/* PIN AREA */
.pinSection {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5D00C3;
  color: white;
  padding: 20px;
  margin: 0 auto; /* Centraliza horizontalmente */
  gap: 10px;
  max-width: 1200px; /* Limita a largura máxima */
  width: 100%; /* Ajusta a largura para se adaptar a telas menores */
  border-radius: 0 0 50px 50px;
}

.pinSection p {
  align-content: center;
  margin: 0;
  font-weight: 600;
}

.pinForm {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pinInput {
  padding: 0 20px;
  border-radius: 50px 0 0 50px;
  border: none;
  font-size: 1em;
  height: 50px;
}

.pinButton {
  height: 50px;
  background-color: #DA71FF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 0 50px 50px 0;
  cursor: pointer;
}

.pinButton:hover {
  background-color: #8d27b3;
}

/* PIN AREA RESPONSIVE */
@media (max-width: 768px) {
  .pinSection {
    margin: 0;
    flex-direction: column;
    width: auto;
    border-radius: 0 0 50px 50px;
    text-align: center;
  }

  .pinForm {
    flex-direction: column;
    width: 100%;
    max-width: none;
  }

  .pinInput,
  .pinButton {
    width: 260px;
    max-width: none;
    border-radius: 50px;
    margin: 5px 0;
    height: 50px; /* Certifica-se de que ambos tenham a mesma altura */
  }

  .pinButton {
    font-size: 1.2em;
    border-radius: 50px;
    width: 300px;
  }
}


.mainContent {
  text-align: center;
}

/* Hero Section */
.hero {
  display: flex;
  justify-content: center; /* Centraliza o conteúdo horizontalmente */
  align-items: center;
  padding: 60px 20px;
  background-color: #FBF8FD;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap; /* Permite que os itens se empilhem em telas menores */
}

.heroContent {
  flex: 1;
  padding-right: 20px;
  width: 100%; /* Ajusta a largura para se adaptar a telas menores */
  max-width: 600px;
  box-sizing: border-box; /* Inclui padding e bordas na largura total */
}

.heroContent h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
}

.highlightText {
  font-weight: 900;
  color: #5D00C3;
}

.description {
  margin-bottom: 30px;
}

.ctaButton {
  background-color: #5D00C3;
  color: white;
  padding: 15px 30px;
  font-size: 1em;
  text-decoration: none;
  border-radius: 50px;
  transition: background-color 0.3s ease;
}

.ctaButton:hover {
  background-color: #450093;
}

.cardIcon {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.disclaimer {
  font-size: 0.8em;
}

.imageSection {
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: linear-gradient(to bottom, #FBF8FD 50%, #F8E4FF 50%);
}

.heroImage {
  position: relative;
  z-index: 1;
  max-width: 100%;
  height: auto;
}

/* Hero Section Responsive */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
    padding: 30px 10px;
  }

  .heroContent {
    padding: 0;
    width: auto;
    max-width: 600px; /* Limita a largura máxima em telas menores */
  }

  .heroContent h1 {
    font-size: 2em;
    max-width: 100%;
  }

  .ctaButton {
    padding: 10px 20px;
    font-size: 0.9em;
  }

  .imageSection {
    width: 100%; /* Garante que a seção de imagem ocupe toda a largura disponível */
    margin-top: 20px; /* Adiciona um espaçamento entre o texto e a imagem */
  }

  .heroImage {
    width: 100%; /* Ajusta a largura para melhor exibição em telas menores */
    
    margin: 0 auto; /* Centraliza a imagem */
  }
}




/* CARDS - MESMO SE VOCE NAO POSSUIR UM PROJETOR */
/* CARDS - MESMO SE VOCE NAO POSSUIR UM PROJETOR */
.cardsSection {
  background: linear-gradient(to bottom, #5D00C3 50%, #FBF8FD 50%);
  padding: 60px 20px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.cardsSection h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: white;
}

.cardsDescription {
  font-size: 1em;
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  color: white;
}

.cardsContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.card {
  flex: 1 1 200px; /* Ajusta os cartões para terem uma largura mínima */
  max-width: 300px; /* Limita a largura máxima dos cartões */
}

.cardImage {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease; /* Adiciona transição suave para o hover */
}

.cardImage:hover {
  transform: scale(1.1);
}

/* CARDS RESPONSIVE */
@media (max-width: 768px) {
  .cardsSection {
    padding: 30px 0px;
  }

  .cardsSection h2 {
    font-size: 1.8em;
  }

  .cardsDescription {
    font-size: 0.9em;
    margin-bottom: 30px;
  }

  .cardsContainer {
    justify-content: flex-start; /* Alinha os cartões ao início */
    flex-wrap: nowrap; /* Evita que os cartões quebrem linha */
    overflow-x: auto; /* Adiciona rolagem horizontal */
    gap: 10px;
    padding: 10px 0;
    scroll-behavior: smooth; /* Adiciona rolagem suave */
    scroll-snap-type: x mandatory; /* Adiciona o comportamento de snap */
  }

  .card {
    flex: 0 0 auto; /* Garante que os cartões não encolham */
    width: 60%; /* Define a largura dos cartões em telas menores */
    scroll-snap-align: center; /* Faz o cartão parar no centro ao rolar */
  }

  .cardImage {
    width: 100%;
    height: auto;
  }
}


/* SLIDER */
.carouselSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 20px;
  background-color: #FBF8FD;
  max-width: 1200px;
  margin: 0 auto;
}

.carouselContent {
  flex: 1;
  padding-right: 20px;
  text-align: left;
}

.carouselContent h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
}

.carouselContent p {
  margin-bottom: 20px;
  font-size: 1.2em;
}

.learnMore {
  display: inline-block;
  margin-bottom: 20px;
  color: #5D00C3;
  text-decoration: underline;
}

.carouselButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.carouselButtons button {
  background: none;
  border: 2px solid #5D00C3;
  border-radius: 25px;
  color: #5D00C3;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
  white-space: nowrap; /* Impede a quebra de linha */
}

.carouselButtons button:hover {
  background: #5D00C3;
  color: white;
}

.carouselButtons .selectedButton {
  background: #5D00C3;
  color: white;
}

.carouselImage {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carouselImage img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.slideIn {
  animation: slideIn 0.3s forwards;
}

.slideOut {
  animation: slideOut 0.3s forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-50%);
    opacity: 0;
  }
}

/* SLIDER RESPONSIVE */
@media (max-width: 768px) {
  .carouselSection {
    display: block;
    flex-direction: column;
    text-align: center;
  }

  .carouselContent {
    padding-right: 0;
  }

  .carouselContent h2 {
    font-size: 2em;
  }

  .carouselContent p {
    font-size: 1em;
  }

  .carouselButtons {
    flex-direction: row;
    overflow-x: auto; /* Adiciona rolagem horizontal */
    gap: 10px;
    scroll-snap-type: x mandatory; /* Adiciona o comportamento de snap */
    -webkit-overflow-scrolling: touch; /* Garante rolagem suave no iOS */
    padding: 10px 0;
  }

  .carouselButtons button {
    flex: 0 0 auto; /* Garante que os botões não encolham */
    scroll-snap-align: center; /* Faz o botão parar no centro ao rolar */
    white-space: nowrap; /* Impede a quebra de linha */
  }

  .carouselButtons {
    width: 100%;
    overflow-x: auto;
  }
}



/* CRIE INTERAJA ANALISE */
.superSlidesSection {
  background-color: #FBF8FD;
  padding: 60px 20px;
  text-align: center;
}

.superSlidesSection h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #000;
}

.superSlidesDescription {
  font-size: 1em;
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  color: #000;
}

.superSlidesContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.superSlideCard {
  flex: 1;
  max-width: 300px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden; /* Esconde a parte da imagem que ultrapassa os limites */
  padding: 20px;
  text-align: left;
  position: relative; /* Necessário para o pseudo-elemento funcionar corretamente */
}

.superSlideImageContainer {
  position: relative;
  overflow: hidden; /* Esconde a parte da imagem que ultrapassa os limites do container */
  border-radius: 20px; /* Aplica o mesmo border-radius ao container */
}

.superSlideImage {
  border-radius: 20px;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease; /* Suaviza a transição do zoom */
}

.superSlideImage:hover {
  transform: scale(1.1) rotate(5deg); /* Faz o zoom na imagem e a rotaciona levemente */
}

.superSlideCard h3 {
  font-size: 2em;
  margin-bottom: 10px;
}

.superSlideCard p {
  font-size: 1em;
}

/* RESPONSIVE */
@media (max-width: 768px) {
  .superSlidesSection {
    padding: 30px 10px;
  }

  .superSlidesSection h2 {
    font-size: 1.8em;
  }

  .superSlidesDescription {
    font-size: 0.9em;
    margin-bottom: 30px;
  }

  .superSlidesContainer {
    flex-direction: column;
    align-items: center;
  }

  .superSlideCard {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .superSlideCard h3 {
    font-size: 1.5em;
  }

  .superSlideCard p {
    font-size: 0.9em;
  }
}



/* sessão educação e corporativo */

.educacaoCorporativoSection {
  background-color: #FBF8FD;
  padding: 60px 20px;
  text-align: center;
}

.educacaoCorporativoContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.educacaoCorporativoCard {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  text-align: left;
}

.educacaoCorporativoImageContainer {
  flex: 1;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}

.educacaoCorporativoImage {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
  border-radius: 20px;
}

.educacaoCorporativoImage:hover {
  transform: scale(1.1) rotate(5deg);
}

.educacaoCorporativoText {
  flex: 1;
}

.educacaoCorporativoText h3 {
  font-size: 3em;
}

.educacaoCorporativoText p {
  font-size: 1em;
}

.educacaoCorporativoButton {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #5D00C3;
  color: white;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.educacaoCorporativoButton:hover {
  background-color: #450093;
}

/* RESPONSIVE */
@media (max-width: 768px) {
  .educacaoCorporativoCard {
    flex-direction: column;
    text-align: center;
  }

  .educacaoCorporativoText h3 {
    font-size: 2em;
  }

  .educacaoCorporativoText p {
    font-size: 0.9em;
  }

  .educacaoCorporativoCard:nth-child(2) .educacaoCorporativoImageContainer {
    order: -1; /* Move a imagem para o início no segundo card */
  }
}





/* Seção Enterprise */
.enterpriseSection {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FBF8FD;
  padding: 60px 0;
  position: relative;
}

.enterpriseContainer {
  background-color: #5D00C3;
  width: 1200px;
  height: 300px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  padding: 20px;
}

.enterpriseBgImageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.enterpriseBgImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: opacity(0.5);
}

.enterpriseText {
  color: white;
  padding: 20px;
  z-index: 2;
  flex: 1;
  text-align: left;
}

.enterpriseText h3 {
  color: #DA71FF;
  font-size: 2em;
  margin-bottom: 10px;
  text-align: left;
}

.enterpriseText p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.enterpriseButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #DA71FF;
  color: white;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.enterpriseButton:hover {
  background-color: #450093;
}

.enterpriseImageContainer {
  flex: 1;
  position: relative;
  overflow: visible;
  height: 100%;
}

.enterpriseApoioImage {
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  height: 120%;
}

/* RESPONSIVE */
@media (max-width: 768px) {
  .enterpriseContainer {
    width: auto; /* Ajusta a largura para automático */
    height: auto; /* Ajusta a altura para automático */
    padding: 20px;
    flex-direction: column; /* Empilha itens verticalmente */
    align-items: center; /* Centraliza itens horizontalmente */
  }

  .enterpriseImageContainer {
    margin-bottom: 60px; /* Adiciona espaço entre a imagem e o texto */
    width: 100%; /* Garante que a imagem ocupe toda a largura disponível */
    order: -1; /* Move a imagem para o início no layout vertical */
    position: relative; /* Necessário para centralizar */
    left: 50%; /* Move a imagem para o meio do contêiner */
    transform: translateX(-70%); /* Centraliza a imagem */
    z-index: 2;
  }

  .enterpriseApoioImage {
    width: auto;
    height: auto; /* Ajusta a altura conforme necessário */
    max-height: 200px; /* Define a altura máxima */
  }

  .enterpriseText {
    text-align: center;
  }

  .enterpriseText h3 {
    font-size: 1.8em;
    text-align: center;
  }

  .enterpriseText p {
    font-size: 1em;
  }

  .enterpriseButton {
    padding: 10px 20px;
    font-size: 1em;
  }
}






/* Impressione com suas apresentações interativas Section */
.impressioneSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #FBF8FD;
  padding: 60px 20px;
  
}

.impressioneSection h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #000;
  max-width: 800px;
}

.impressioneDescription {
  font-size: 1.2em;
  margin-bottom: 30px;
  max-width: 800px;
  color: #000;
}

.impressioneButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.impressioneButton {
  background-color: #5D00C3;
  color: white;
  padding: 15px 30px;
  font-size: 1.2em;
  text-decoration: none;
  border-radius: 50px;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.impressioneButton:hover {
  background-color: #450093;
}

.noCreditCard {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #6A15C8;
}

.noCreditCard img {
  margin-right: 5px;
}

/* RESPONSIVE */
@media (max-width: 768px) {
  .textContainer h2 {
    font-size: 2em;
  }

  .textContainer p {
    font-size: 1em;
  }

  .ctaButton {
    padding: 10px 20px;
    font-size: 1em;
  }
}