.scoreboard {
    padding: 20px;
    text-align: center;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .podium {
    flex: 1;
  }
  
  .trophyContainer {
    text-align: center;
  }
  
  .trophyLottie {
    width: 100%;
    height: 200px;
    background-size: contain;
  }
  
  .winners {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 20px;
    height: 30vh;
  }
  
  .winner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
    position: relative;
    height: 100%;
  }
  
  .avatarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    z-index: 1;
  }
  
  .avatar {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }
  
  .name {
    margin-top: 5px;
  }
  
  .barContainer {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    height: 100%;
  }
  
  .bar {
    width: 100%;
    background-color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border-radius: 5px;
    position: relative;
    bottom: 0;
  }
  
  .gold .bar {
    background-color: gold;
  }
  
  .silver .bar {
    background-color: silver;
  }
  
  .bronze .bar {
    background-color: #cd7f32;
  }
  
  .score {
    font-weight: bold;
    color: white;
    position: absolute;
    top: 10px;
  }
  
  .participantListContainer {
    flex: 1;
  }
  
  .participantList {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
  }
  
  .participantItem {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 8px;
    color: white;
    background-color: #6a0dad;
  }
  
  .participantItem img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  
  .participantName {
    margin-left: 10px;
    flex: 1;
  }
  
  .participantScore {
    font-weight: bold;
  }
  