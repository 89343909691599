/* //src/interactions/quiz/QuizParticipant.module.css */

.timerContainer {
  width: 80px;
  height: 80px;
  margin: 20px auto;
}

.optionsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.optionButton {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.optionColor1 { background-color: #ffab00; }
.optionColor2 { background-color: #ff5722; }
.optionColor3 { background-color: #4caf50; }
.optionColor4 { background-color: #2196f3; }

.optionItem {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
}

.correct {
  background-color: #4caf50;
  color: white;
}
