/* src/pages/CreatePresentation.module.css */

/* GERAL */
.createPresentationContainer {
  display: flex;
  transition: all 0.3s ease;
}

.firstColumn {
  width: 300px;
  height: 100vh;
  box-shadow: 10px 0 30px rgba(0, 0, 0, 0.1);
  z-index: 3;
  background-color: #FBF8FD;
}

.secondColumn, .thirdColumn {
  background-color: #FBF8FD;
  padding: 0 20px;
  overflow-y: auto;
}

/* HEADER */
.backButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #5d00c3;
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 15px;
}

.backButton:hover {
  background-color: #410086;
  text-shadow: 0 0 5px #6e6e6e;
  transform: translateY(-3px); /* Leve levantada */
}

.headerGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ECE1F8;
}

.headerGroup h3 {
  color: #5d00c3;
}

.rightSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.rightSide p {
  color: #666;
  font-size: 12px;
}

/* EDITÁVEIS */
.editable {
  cursor: pointer;
  font-weight: bold;
  /* margin: 10px 0; */
}

.editing {
  border-bottom: 1px solid #5d00c3;
}

.editInput {
  font-size: 1em;
  border: none;
  background: transparent;
  outline: none;
  width: 100%;
}

/* DIVISORES */
.divider {
  border-top: 1px solid #ECE1F8;
  margin: 20px 0;
}

/* BOTÕES */
.addButton {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: #6A15C8;
  color: white;
  padding: 15px 30px;
  border-radius: 50px;
  cursor: pointer;
  font-weight: bold;
  border: none;
}

.addButton:hover {
  transform: scale(1.05) rotate(-5deg);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.enabled {
  cursor: pointer;
  opacity: 1;
}

/* LISTA DE INTERAÇÕES */
.interactionListContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.fixedContent {
  flex-shrink: 0; /* Fixa a altura do conteúdo fixo */
  padding: 10px 20px 0px 20px;
}

.scrollableContent {
  overflow-y: auto; /* Adiciona o scroll vertical */
  flex-grow: 1; /* Permite que o conteúdo scrollable cresça para preencher o espaço disponível */
}

.interactionList {
  padding: 10px;
  flex-grow: 1;
}

.interactionItem {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.interactionItem p {
  color: #6e6e6e;
}

.interactionItem:hover {
  transform: scale(1.05);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.interactionItem:hover .interactionButtons {
  display: flex;
  flex-direction: column;
}

.interactionTitle {
  font-weight: bold;
  color: #5d00c3;
}

.interactionDescription {
  font-size: 14px;
}

/* ITEM SELECIONADO */
.selectedItem {
  transition: none;
  color: white;
  background-color: #5d00c3;
  transform: scale(1.05);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.selectedItem .interactionDescription {
  color: white;
}

.selectedItem p {
  color: white;
}

/* BOTÕES DE INTERAÇÃO */
.interactionButtons {
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
  gap: 5px;
}

.interactionButtons button {
  /* background-color: transparent; */
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px; /* Bordas arredondadas */
  width: 25px; /* Largura do botão */
  height: 25px; /* Altura do botão */
}

.duplicateButton {
  background-color: #E6C65A;
}

.deleteButton {
  background-color: #E65A5A;
}

.duplicateButton:hover {
  background-color: #E6C65A;
  transform: scale(1.2);
}

.deleteButton:hover {
  background-color: #E65A5A;
  transform: scale(1.2);
}

/* SEGUNDA COLUNA: FORMULÁRIOS E CONFIGURAÇÕES */
.secondColumn {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  box-shadow: 10px 0 30px rgba(0, 0, 0, 0.1); /* Adicionado para garantir que o conteúdo principal e a área de botões sejam separados */
}

.secondColumnContent {
  width: 100%;
  flex-grow: 1; /* Adicionado para garantir que este conteúdo ocupe todo o espaço disponível */
}

.secondColumnHeader{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; /* Permite que os itens quebrem linha se necessário */
  align-items: center; /* Alinha os itens verticalmente no centro */
  gap: 10px; /* Adiciona espaço entre os itens */
}

.saveCancelArea {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0; /* Adicionado para espaçamento */
}

.secondColumnContent{
  display: flex;
  gap: 10px;
}

.secondColumnContent label {
  font-weight: 600;
}

.secondColumnContent input {
  padding: 15px 10px ;
  border-radius: 10px;
  border: none;
}

.secondColumnContent input:focus {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
  border: solid 2px;
  border: #666;
}

.secondColumnContent input:hover{
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}


.saveInteractionButton{
  background-color: #6A15C8;
  color: white;
  padding: 15px 30px;
  border-radius: 50px;
  cursor: pointer;
  font-weight: bold;
  border: none;
}

.saveInteractionButton:hover{
  transform: translateY(-3px);
}

.cancelButton{
   background: transparent;
   text-decoration: underline;
   border: none;
   color: #5D00C3;
   cursor: pointer;
}

.cancelButton:hover {
  color: #812cdd;
}

/* TERCEIRA COLUNA: INTERAÇÕES E PREVIEW */

.thirdColumn {
  background-color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative; /* Adicionado para garantir que o PreviewContainer se ancore corretamente */
}

.thirdColumnContent {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Evitar scroll */
}

.previewSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
}

.previewText {
 
  font-size: 24px;
  color: #666;
}

.searchInput {
  width: 40%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
}

/* CARTÕES DE INTERAÇÃO */
.interactionCards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.card {
  background-color: #FBF8FD;
  width: 15vw;
  height: 10vw;
  min-width: 200px;
  min-height: 150px;
  border-radius: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  position: relative; /* Adicione esta linha */
}

.card:hover {
  transform: scale(1.05) rotate(5deg);
}

.selectedInteractionTitle {
  color: #5d00c3;
}

.cardImage {
  width: 80%;
  height: auto;
  object-fit: contain;
}

.comingSoon {
  position: absolute;
  top: -10px; /* Ajuste conforme necessário */
  right: -10px; /* Ajuste conforme necessário */
  background-color: #5d00c3;
  color: white;
  padding: 10px 30px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
}

.cardTitle {
  font-weight: bold;
  margin-top: 10px;
  color: #5d00c3;
  font-size: 1em;
}

/* GRUPO DE ENTRADAS */
.inputGroup {
  display: flex;
  flex-direction: column;
}

.inputGroup h2 {
  color: #5d00c3;
  margin: 10px 0;
}

.inputGroup h2:hover {
  text-shadow: 0 0 5px #D764FF;
  transform: translateY(-3px); /* Leve levantada */
}

.inputGroup p:hover {
  text-shadow: 0 0 5px #D764FF;
  transform: translateY(-3px); /* Leve levantada */
}

.inputLabel {
  font-size: 14px;
  color: #666;
}

/* Adicionar novas classes para a visualização mobile */
@media (max-width: 768px) {
  .mobileView {
    display: flex;
    flex-direction: column;
  }

  .firstColumn {
    display: block;
    width: 100%;
  }

  .firstColumn.hide {
    display: none;
  }

  .thirdColumn {
    display: none;
  }

  .thirdColumn.show {
    display: block;
    width: 100%;
    transition: all 0.3s ease;
  }

  .secondColumn {
    display: none;
  }

  .secondColumn.show {
    display: block;
    width: 100%;
  }

  .addButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6A15C8;
    color: white;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    font-weight: bold;
    border: none;
    width: 90%;
    margin: 10px auto;
  }

  .addButton:hover {
    transform: scale(1.05) rotate(0deg);
  }

  .interactionItem .interactionButtons {
    display: flex;
    flex-direction: column;
  }
  
}

