/* Estilos globais */
.myPresentationsContainer {
  display: flex;
  margin-left: 340px;
  background-color: #FBF8FD;
}

.mainContent {
  width: 100%;
}

.filterSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.searchInput {
  width: 70%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Garante que o padding e o border sejam incluídos na largura total */
}

.sortSelect {
  width: 25%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Garante que o padding e o border sejam incluídos na largura total */
}

.recentPresentations {
  padding: 40px;
}

.presentationsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.recentPresentationHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.presentationsHeader h3 {
  color: #5d00c3;
  margin-bottom: 5px;
}

.presentationsHeader p {
  color: #666;
  margin-top: 0;
}

.addPresentationButton {
  font-weight: bold;
  background-color: #5d00c3;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.addPresentationButton img {
  margin-right: 10px;
}

.presentationCards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
}

.presentationCard {
  text-align: center;
  background-color: #fff;
  width: 300px;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  color: #666;
}

.presentationCard h4 {
  color: #5d00c3;
  margin-bottom: 10px;
}

.presentationActions {
  display: flex;
  justify-content: space-around;
  margin-top: auto;
}

.presentationActions button {
  width: 46px;
  height: 46px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.playButton {
  background-color: #5D00C3;
}

.duplicateButton {
  background-color: #E6C65A;
}

.editButton {
  background-color: #3AAC60;
}

.deleteButton {
  background-color: #E65A5A;
}

.playButton:hover, .duplicateButton:hover, .editButton:hover, .deleteButton:hover{
  transform: scale(1.05) rotate(-5deg);
}

.presentationUpdatedAt {
  color: #9F70D4;
  font-size: 12px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .mainContent {
    margin-top: 50px;
    margin-left: 0;
  }

  .myPresentationsContainer {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }

  .filterSection {
    flex-direction: column;
  }

  .searchInput, .sortSelect {
    width: 100%;
    margin-bottom: 10px;
  }

  .recentPresentationHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .presentationsHeader {
    display: block;
  }

  .userAvatar {
    display: none;
  }

  .planBadge {
    display: none;
  }

  .upgradeButton {
    display: none;
  }

  .card {
    background-color: #FBF8FD;
    height: 180px;
    min-width: 250px;
  }

  .addPresentationButton {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 60px;
  }

  .searchInput {
    width: 100%;
    box-sizing: border-box; /* Garante que o padding e o border sejam incluídos na largura total */
  }
}
