/* src/interactions/WordCloudProjection.module.css */

.wordcloudProjectionContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;  
  flex-direction: column;
  font-weight: 900;

}


