/* src/interactions/InsightsParticipant.module.css */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.insightsParticipantContainer {
  display: flex;
  flex-direction: column;
  height:65vh;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden; /* Garante que o conteúdo não ultrapasse a altura da tela */
}

.responsesContainer {
  flex: 1; /* Para ocupar o espaço restante */
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 60px; /* Espaço reservado para o input fixo */
}

.insightInputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  bottom: 20px; /* Espaço desejado da parte inferior */
  left: 0;
  padding: 10px 20px;
  box-sizing: border-box;
  z-index: 10; /* Garante que o input fique sobre outros elementos */
}

.insightInput {
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  height: 40px;
  border: none;
  border-radius: 50px 0 0 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: box-shadow 0.3s ease-in-out;
}

.insightInput::placeholder {
  color: #D8C2F1;
}

.insightSubmit {
  height: 60px;
  padding: 10px 20px;
  background-color: #5D00C3;
  color: white;
  border: none;
  border-radius: 0 50px 50px 0;
  cursor: pointer;
}

.insightItem {
  background-color: #5d00c3;
  color: white;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
  text-align: center;
  align-content: center;
  flex-grow: 1;
  opacity: 0; /* Inicialmente invisível */
  animation: fadeIn 0.5s forwards; /* Animação de entrada */
  transition: flex-basis 0.5s ease-in-out, transform 0.5s ease-in-out;
  min-height: 60px;
  font-weight: 600;
}

.widthSmall {
  flex: 1 1 150px;
}

.widthMedium {
  flex: 2 1 300px;
}

.widthLarge {
  flex: 3 1 450px;
}
