.scoreboard {
  padding: 20px;
  text-align: center;
}

.participantList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.participantItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px; /* Aumentando o padding para itens maiores */
  margin-bottom: 15px; /* Aumentando a margem inferior */
  border-radius: 8px;
  color: white;
  font-size: 1.5em; /* Aumentando o tamanho da fonte */
}

.participantItem img {
  border-radius: 50%;
  width: 60px; /* Aumentando o tamanho da imagem */
  height: 60px; /* Aumentando o tamanho da imagem */
}

.gold {
  background-color: gold;
}

.silver {
  background-color: silver;
}

.bronze {
  background-color: #cd7f32; /* bronze color */
}

.purple {
  background-color: #6a0dad; /* youpin purple */
}

.name {
  flex: 1;
  text-align: left;
  margin-left: 20px; /* Aumentando a margem esquerda */
}

.score {
  font-weight: bold;
}
