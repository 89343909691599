.signupPage {
  background-color: #FBF8FD;
  background-image: url('../images/sgnin-signup-background-image.png');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.signupFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.signupLogo {
  width: 200px;
  margin-bottom: 20px;
  cursor: pointer;
}

.signupLogo:hover {
  opacity: 0.6;
}

.signupInput {
  
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #C1C1C1;
  border-radius: 50px;
  box-sizing: border-box;
  height: 60px; /* Altura dos inputs */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Adiciona sombra */
}

.signupInput:hover {
  border-color: #5D00C3;
}

.signupButton {
  
  font-size: 16px;
  font-weight: 600;
  background-color: #5D00C3;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  width: 100%;
  height: 60px; /* Altura do botão */
  transition: opacity 0.3s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Adiciona sombra */
}

.signupButton:hover {
  background-color: #430B81;
}

.loginPrompt {
  margin: 20px 0 10px 0;
  color: #333;
}

.loginLink {
  font-size: 16px;
  font-weight: 600;
  color: #5D00C3;
  text-decoration: none;
  
}

.loginLink:hover {
  text-decoration: underline;
}

.errorMessage {
  color: red;
  margin: 10px 0;
}

.divisor {
  width: 100%;
  height: 1px;
  background-color: #ECE1F8;
  margin: 20px 0;
}

.signinDivider {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
  color: #D764FF;
}

.signinDivider::before,
.signinDivider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ECE1F8;
}

.signinDivider::before {
  margin-right: .25em;
}

.signinDivider::after {
  margin-left: .25em;
}

.signinGoogleButton {
  font-size: 16px;
  font-weight: 600;
  background-color: #5D00C3;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: 1px solid #C1C1C1;
  border-radius: 50px;
  cursor: pointer;
  width: 100%;
  height: 60px; /* Altura do botão */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Adiciona sombra */
}

.signinGoogleButton img {
  margin-right: 10px;
}

.signinGoogleButton:hover {
  background-color: #430B81;
}
