.quizProjection {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.questionText {
    margin-top: 20px;
    color: #333;
    font-size: 1.5em;
    text-align: center;
}

.timerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    max-width: 200px;
    /* Tamanho máximo do timer */
    margin: auto;
    /* Centraliza o timer horizontalmente */
    font-family: "montserrat";
    font-weight: 900;
}

.questionImage {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.optionsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    /* Espaçamento entre as opções */
    margin-top: auto;
    margin-bottom: 0px;
}

.optionItem {
    display: flex;
    padding: 15px 20px;
    border-radius: 5px;
    font-size: 1.2em;
    text-align: center;
    color: white;
    /* Texto branco */
    cursor: pointer;
    transition: background-color 0.3s;
    flex: 0 1 calc(50% - 10px);
    /* Ajuste para 2 colunas por linha */
    box-sizing: border-box;
    min-width: 300px;
    min-height: 100px;
    align-items: center;
    justify-content: center;
}

.optionItem:hover {
    opacity: 0.8;
}

.optionColor1 {
    background-color: #E6C65A;
    /* Cor da primeira opção */
}

.optionColor2 {
    background-color: #3AAC60;
    /* Cor da segunda opção */
}

.optionColor3 {
    background-color: #D764FF;
    /* Cor da terceira opção */
}

.optionColor4 {
    background-color: #E65A5A;
    /* Cor da quarta opção */
}

.incorrectAnswer {
    opacity: 0.3;
}